export type ApplicationScoreQueryResult = {
  items: ApplicationScore[];
  pagination: Pagination;
};

export type ApplicationScoreMetadataQueryResult = {
  pagination: {
    total: Pagination;
    [ApplicationStatus.LANDLORD_REJECTED]?: Pagination;
    [ApplicationStatus.PRE_SELECTED]?: Pagination;
    [ApplicationStatus.SELECTED]?: Pagination;
  };
};

export type Pagination = {
  totalItems: number;
  totalPages: number;
  currentPage: number;
  pageSize: number;
};

export type ApplicationScore = {
  applicantId: string;
  scores: Scores;
  scoreExpiryTime: string;
  submitDate: string;
  userInfo: UserInfo;
  userData: UserData;
  userVerifications?: UserVerifications;
  application: Application;
};

export type UserInfo = {
  personalData: UserPersonalData;
  employment: Employment;
  rental: {
    kids: number;
    adults: number;
    missedRent: boolean;
    pets: boolean;
    smoker: boolean;
    insolvency: boolean;
    petsDescription: string | null;
    petsDetails: PetDetail[];
  };
  info?: {
    moveInDate?: Date;
    residencePermit?: false;
  };
  email?: string;
};

export type Employment = {
  type: string;
  netIncome: number;
  probation: boolean;
  limitedTerm: boolean;
  position?: string;
  employerName?: string;
  employedDate?: Date | null;
};

export type PetTypes = 'dog' | 'cat' | 'bird' | 'fish' | 'rodent' | 'reptile' | 'other';
export interface PetDetail {
  count?: number;
  type?: PetTypes | string;
  details?: string;
}

export type Application = {
  id: string;
  date: string;
  status: ApplicationStatus;
  rent: number;
};

export type Scores = {
  overAllScore: number;
  identity: Identity;
  finHealth: {
    liquidity: number;
    cashflow: number;
    overdraft: number;
    overAllScore: number;
  };
  rental: {
    regularity: number;
    validation: number;
    history: number;
    insurance: number;
    overAllScore: number;
  };
  employment: {
    salary: number;
    limitations: number;
    validation: number;
    overAllScore: number;
  };
  specificScores: {
    name: string;
    description: string;
    affordability: number;
    balance: number;
    overAllScore: number;
  };
};

export type socialSurvey = {
  happy_life: number;
  open_experience: number;
  live_alone: number;
  sustainability: number;
  tasks: number;
  quiet_atmosphere: number;
  interaction_housemates: number;
  independence: number;
  enjoyable_time: number;
  reference_person: number;
  events_cultures: number;
  clean_environment: number;
};

export type Identity = {
  name: string;
  email: string;
  mobile: string;
};

export type UserPersonalData = {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  selfDescription?: string;
  address: {
    ggplaceId: string;
    street: string;
    streetNumber: string;
    postalCode: string;
    city: string;
    state: string;
    country: string;
    description: string;
  };
  birthDate?: string;
};

export type submissionData = {
  applicationId: string;
  name: string;
  email: string;
  pets: boolean;
  petsDescription: string;
  socialScore: number;
  income: number;
  guarantorIncome: number;
};

export type UserData = {
  'social-survey': socialSurvey;
  'personal-details': UserPersonalDetails;
};

export type UserPersonalDetails = {
  address: string;
};

export type UserVerifications = {
  emailVerified: boolean;
  identityVerified: boolean;
  rentVerified: boolean;
  incomeVerified: boolean;
};

export enum ApplicationStatus {
  LANDLORD_REJECTED = 'landlordRejected',
  SELECTED = 'selected',
  PRE_SELECTED = 'preSelected',
}

export type UpdateApplicationDto = {
  id: string;
  status: ApplicationStatus;
};

export type RequestAdditionalDocumentsDto = {
  id: string;
  documentBody: string;
  firstName: string;
};

export enum AssessmentStatus {
  GREEN = 'green',
  YELLOW = 'yellow',
  RED = 'red',
  INVALID = 'invalid',
}
